<template>
  <vuestic-widget :loading="loading" class="number-edit-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit Number <b>{{ formData.number | phone }}</b></span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="userCreateForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="numberEditForm">
        <div class="row">
          <div class="col-sm-12">
            <text-input name="Name" v-model="formData.name" label="Name" validate="required" />
          </div>
          <div class="col-md-12">
            <phone-number-input ref="phoneInput" name="Phone" label="call forward number"
              v-model="formData.forward_number" :allowValidation="false" />
          </div>
          <!-- <div class="col-md-12 mt-3">
            <label class="font-weight-bold">Press Any Key <a v-b-tooltip.hover
                title="Business agents need to press any key to accept incoming calls."
                class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="formData.press_number" class="align-middle" :offcolor="true" style="width: 300px">
              <span slot="trueTitle">Yes</span>
              <span slot="falseTitle">No</span>
            </vuestic-switch>
          </div> -->
          <div class="col-md-12 mt-4">
            <p v-if="formData.press_number" class="text-primary font-weight-bold"><i>Agents need to press any key to accept incoming calls</i></p>
            <p v-else class="text-primary font-weight-bold"><i>Press any key feature is turned off</i></p>
          </div>

          <div class="col-md-12">
            <!-- <label for="callWhisperEnabled" class="font-weight-bold">
              <span>Call Whisper <a v-b-tooltip.hover
              title="This will be played before agents accept incoming calls by pressing any key."
              class="fa fa-question-circle tooltip-icon" /></span>
            </label> -->
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" id="callWhisperEnabled" v-model="formData.call_whisper_enabled" />
              <label for="callWhisperEnabled" class="font-weight-bold">
                <span class="abc-label-text">Call Whisper <a v-b-tooltip.hover
                title="This will be played before agents accept incoming calls by pressing any key."
                class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
          </div>
          <div class="col-md-12">
            <b-tabs v-model="formData.call_whisper_type" small card>
              <b-tab title="Text">
                <textarea-input name="WhisperText" v-model="formData.call_whisper_text" :required="true" />
              </b-tab>
              <b-tab title="Audio">
                <div class="row">
                  <div class="col-md-12">
                    <VoiceRecording label="" v-model="formData.call_whisper_audio" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="col-md-12">
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" id="callRecordingEnabled" v-model="formData.call_recording_enabled" />
              <label for="callRecordingEnabled" class="font-weight-bold">
                <span class="abc-label-text">Call Recording <a v-b-tooltip.hover
                title="Contacts will hear this when they directly call the business."
                class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
          </div>
          <div v-if="formData.call_recording_enabled" class="col-md-12">
            <b-tabs v-model="formData.call_recording_type" small card>
              <b-tab title="Text">
                <textarea-input name="RecordingText" v-model="formData.call_recording_text" :required="true" />
              </b-tab>
              <b-tab title="Audio">
                <div class="row">
                  <div class="col-md-12">
                    <VoiceRecording label="" v-model="formData.call_recording_audio" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="col-md-12">
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" id="blockIncomingSms" v-model="formData.block_incoming_sms" />
              <label for="blockIncomingSms" class="font-weight-bold">
                <span class="abc-label-text">Block Incoming SMS <a v-b-tooltip.hover
                title="Business won't receive messages from contacts."
                class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <p class="text-primary font-weight-bold"><i>Max timeout value is 120s (2 mins)</i></p>
          </div>
          <div class="col-md-12">
            <label>Incoming Call Timeout (s)</label>
            <text-input name="Timeout" type="number" v-model="formData.incoming_call_timeout" :max="120" validate="required" />
          </div>
          <div class="col-md-12">
            <label>Outgoing Call Timeout (s)</label>
            <text-input name="Timeout" type="number" v-model="formData.outgoing_call_timeout" validate="required" />
          </div>
          <div class="col-md-12">
            <label>Voicemail Timeout (s)</label>
            <text-input name="Timeout" type="number" v-model="formData.voicemail_timeout" validate="required" />
          </div>
          <div class="col-md-12 mt-3">
            <label class="font-weight-bold">Voicemail Greeting <a v-b-tooltip.hover
                title="This voice will be played when customer directly calls the business and no agent is available. Default voice will be played unless it's set."
                class="fa fa-question-circle tooltip-icon" /></label>
            <VoiceRecording label="" v-model="formData.voice_file" />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <router-link class="btn btn-primary btn-danger mr-2" :to="{ name: 'business.numbers' }">
              <span>Back</span>
            </router-link>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import { mapState } from 'vuex';
import VoiceRecording from "../../common/VoiceRecording"
import Loading from 'vue-loading-overlay'
import axios from 'axios'

export default {
  components: {
    VoiceRecording,
    Loading
  },
  data() {
    return {
      loading: false,
      users: [],
      formData: {
        name: '',
        number: '',
        forward_number: '',
        // press_number: true,
        block_incoming_sms: false,
        call_whisper_enabled: true,
        call_whisper_text: '',
        call_whisper_audio: null,
        call_recording_enabled: false,
        call_recording_text: '',
        call_recording_audio: null,
        incoming_call_timeout_enabled: false,
        incoming_call_timeout: 30,
        outgoing_call_timeout_enabled: false,
        outgoing_call_timeout: 30,
        voicemail_timeout: 30,
        voice_file: undefined,
        call_whisper_type: 0,
        call_recording_type: 0,
      },
      isLoadingUpload: false,
    };
  },

  computed: {
    ...mapState('number', {
      processing: 'processing',
    }),

    business() {
      return this.$store.state.auth.user.business
    }
  },

  mounted() {
    if (this.$route.params.id) {
      const id = this.$route.params.id
      this.loadData(id);
    } else {
      this.redirectOnError()
    }
  },
  methods: {
    redirectOnError() {
      this.$router.push({ name: 'business.numbers' });
    },

    loadData(id) {
      this.loading = true;
      this.$store
        .dispatch('number/get', id)
        .then((data) => {
          this.loading = false;
          this.initData(data);
        })
        .catch(() => {
          this.loading = false;
          this.redirectOnError()
        })
    },

    initData(data) {
      this.formData = {
        ...data
      }
    },

    onFormSubmit() {
      const param = {
        id: this.formData.id,
        data: {
          ...this.formData,
        }
      }
      this.$store
        .dispatch('number/update', param)
        .then(() => {
          this.loadData(this.formData.id);
        })
        .catch(() => {
        })
    },
  },

};
</script>

<style lang="scss">
.number-edit-page {
  .abc-checkbox {
    padding-left: 7px;

    label {
      color: #4f5467;
    }
  }
}
</style>
<style lang="scss" scoped>
.numberEditForm {
  max-width: 768px;
}
</style>